import React, { ReactElement, useEffect, useState } from 'react';

import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import { Button } from '@mui/material';

type TableToolBarProps = {
  numSelected?: string[];
  search?: string;
  onFilter?: (event: any) => void;
  ButtonAdd?: ReactElement;
  ButtonExport?: ReactElement;
  onCompensated?: () => void;
  onSearch: (searchTerm: string) => void;
};

const TableToolBar = ({
  numSelected = [],
  search,
  onFilter,
  ButtonAdd,
  ButtonExport,
  onCompensated,
  onSearch,
}: TableToolBarProps) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500); // 500ms delay
    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    onSearch(debouncedSearchTerm);
  }, [debouncedSearchTerm, onSearch]);

  const handleInputChange = (e: any) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div className="flex justify-end py-2 items-center gap-x-3">
      {numSelected?.length > 0 && (
        <div className="flex">
          <div
            className="px-4 py-2 bg-gray-200 border border-gray-500 
        rounded-l-lg 2xl:text-sm xl:text-sm lg:text-xs sm:text-xs md:text-xs border-opacity-20 text-black"
          >
            จำนวนคาร์บอนที่ชดเชย (ตัน)
          </div>
          <div className="px-4 py-2 bg-gray-200 border border-r-0 border-l-0 border-gray-500 2xl:text-sm xl:text-sm lg:text-xs sm:text-xs md:text-xs border-opacity-20 text-black">
            {numSelected?.length}
          </div>
          <Button
            color="success"
            variant="contained"
            onClick={onCompensated}
            sx={{
              color: 'black',
              backgroundColor: '#B4E380',
              '&:hover': {
                backgroundColor: '#88D66C',
              },
              borderTopLeftRadius: '0px',
              borderBottomLeftRadius: '0px',
              borderTopRightRadius: '10px',
              borderBottomRightRadius: '10px',
            }}
          >
            ยืนยันการชดเชย
          </Button>
        </div>
      )}
      {ButtonExport}
      <TextField
        variant="outlined"
        size="small"
        placeholder="พิมพ์เพื่อค้นหา"
        type="search"
        sx={{
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              borderColor: '#6CB2A1',
            },
          },
        }}
        InputProps={{
          sx: { borderRadius: 2, backgroundColor: 'white' },
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        onChange={handleInputChange}
        value={searchTerm}
      />
      {ButtonAdd}
    </div>
  );
};

export default TableToolBar;
