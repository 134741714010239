import { eventEFValue } from './eventType'
import moment from 'moment'
import CryptoJS from 'crypto-js'

export const formatterNumber = new Intl.NumberFormat('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
export const formatterDate = 'en-GB'

const myDate = new Date();

// Define an array of month names in Thai
const thaiMonths = [
  "มกราคม", // January
  "กุมภาพันธ์", // February
  "มีนาคม", // March
  "เมษายน", // April
  "พฤษภาคม", // May
  "มิถุนายน", // June
  "กรกฎาคม", // July
  "สิงหาคม", // August
  "กันยายน", // September
  "ตุลาคม", // October
  "พฤศจิกายน", // November
  "ธันวาคม" // December
];
const engMonths = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

export const listUploadImg = "image/png, image/jpg, image/jpeg"

export const listUploadFile = "image/png, image/jpg, image/jpeg, application/pdf"

// Get the day, month, and year
const day = myDate.getDate();
const month = myDate.getMonth(); // Returns 0-based index (0 = January, 1 = February, ..., 11 = December)
const year = myDate.getFullYear();

// Format the date string
export const currentDateInThai = `วันที่ ${day} ${thaiMonths[month]} ${year}`;

export const currentDateInEng = `${engMonths[month]} ${day}, ${year}`;

export const isDateLessThanCurrent = (date: string) => {
  const currentDate = moment();
  const inputDate = moment(date);
  return inputDate.isBefore(currentDate);
};

export const calculateEqCo2Standard = (eventType: string) => {
  let tmpCreateDataStr = sessionStorage.getItem('tmpCreateData')
  let tmpCreateData = JSON.parse(tmpCreateDataStr!)
  let attendee = tmpCreateData.attendees
  let attendeeOnline = tmpCreateData.attendeesOnline
  let startDate = tmpCreateData.startDate
  let endDate = tmpCreateData.endDate
  let duration = tmpCreateData.projectDuration
  let projectSqm = tmpCreateData.projectSQm
  let distanceExpress = tmpCreateData.distanceExpressStandard
  let weightExpress = tmpCreateData.weightExpressStandard
  let durationDay = moment.duration(moment(endDate).diff(moment(startDate)));
  let diffDay = durationDay.asDays() + 1;

  switch (eventType) {
    case 'setTransporter':
      var totaleqCo2 = 0.0
      eventEFValue[eventType]?.map((item: any) => {
        totaleqCo2 += parseFloat(attendee) * diffDay * item
      })
      return {
        eventCategory: eventType,
        totalCo2eq: totaleqCo2,
        type: 'standard'
      }
    case 'setFoodDrink':
      var totaleqCo2 = 0.0
      eventEFValue[eventType]?.map((item: any) => {
        totaleqCo2 += parseFloat(attendee) * diffDay * item * 3
      })
      return {
        eventCategory: eventType,
        totalCo2eq: totaleqCo2,
        type: 'standard'
      }
    case 'setRestPlace':
      var totaleqCo2 = 0.0
      eventEFValue[eventType]?.map((item: any) => {
        totaleqCo2 += parseFloat(attendee) * diffDay * item
      })
      return {
        eventCategory: eventType,
        totalCo2eq: totaleqCo2,
        type: 'standard'
      }
    case 'setWaste':
      var totaleqCo2 = 0.0
      eventEFValue[eventType]?.map((item: any) => {
        totaleqCo2 += parseFloat(attendee) * parseFloat(duration) * item
      })
      return {
        eventCategory: eventType,
        totalCo2eq: totaleqCo2,
        type: 'standard'
      }
    case 'setElectricSite':
      var totaleqCo2 = 0.0
      eventEFValue[eventType]?.map((item: any) => {
        totaleqCo2 += parseFloat(attendee) * parseFloat(projectSqm) * item
      })
      return {
        eventCategory: eventType,
        totalCo2eq: totaleqCo2,
        type: 'standard'
      }
    case 'setElectric':
      var totaleqCo2 = 0.0
      eventEFValue[eventType]?.map((item: any) => {
        totaleqCo2 += parseFloat(attendeeOnline) * parseFloat(duration) * item
      })
      return {
        eventCategory: eventType,
        totalCo2eq: totaleqCo2,
        type: 'standard'
      }
    case 'setGift':
      var totaleqCo2 = 0.0
      eventEFValue[eventType]?.map((item: any) => {
        totaleqCo2 += (parseFloat(attendee) + parseFloat(attendeeOnline)) * item
      })
      return {
        eventCategory: eventType,
        totalCo2eq: totaleqCo2,
        type: 'standard'
      }
    case 'setExpress':
      var totaleqCo2 = 0.0
      eventEFValue[eventType]?.map((item: any) => {
        totaleqCo2 += parseFloat(distanceExpress) * parseFloat(weightExpress) * item
      })
      return {
        eventCategory: eventType,
        totalCo2eq: totaleqCo2,
        type: 'standard'
      }
  }

}

export function getSessionStorageData(key: string) {
  let tmpCreateDataStr = sessionStorage.getItem(key);
  let tmpCreateData = tmpCreateDataStr ? JSON.parse(tmpCreateDataStr!) : {};
  if (!tmpCreateData) {
    tmpCreateData = {}
  }
  return tmpCreateData
}

export function setSessionStorageData(key: string, value: any) {
  sessionStorage.setItem(key, JSON.stringify(value));
}

export function hashValue(str: string) {
  return CryptoJS.SHA256(str).toString(CryptoJS.enc.Hex);
}

export const blockInvalidChar = (e: React.KeyboardEvent<HTMLInputElement>) => {
  if (['e', 'E', '+', '-'].includes(e.key)) {
    e.preventDefault();
  }
};

export function getDecimalDigit(number: number) {
  const decimalPart = number.toString().split('.')[1];
  if (decimalPart) {
    return decimalPart.length;
  }
  return 0;
}

export function formatterNumberDigit(digit: number) {
  return new Intl.NumberFormat('en-US', { maximumFractionDigits: digit, minimumFractionDigits: digit });
}

export function bytesToMegabytes(bytes: number): string {
  if (bytes === undefined || bytes === null) {
    return "0 MB"
  }
  const megabytes = bytes / (1024 * 1024);
  return `${megabytes.toFixed(2)} MB`;
}

export function convertToBlobPart(file: any): BlobPart {
  return file;
}
