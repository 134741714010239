import React, { useEffect, useState, ChangeEvent } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import { useForm, SubmitHandler } from 'react-hook-form';
import { PlaceEvent } from "../../constants/screen/dropdownList";
import { blockInvalidChar } from "@src/constants/screen/util";

type FormValues = {
    projectName: string;
    startDate: string;
    endDate: string;
    duration: number;
    selectPlace: string;
    placeOther: string;
    outDoorPart: number;
    energyPart: number;
    sQm: number;
    usageSQm: number;
    attendees: number;
    attendeesOnline: number;
    forigener: number;
    owner: string;
};

const MyForm: React.FC = () => {
    const navigate = useNavigate()
    const { id, event } = useParams()
    const { t, i18n } = useTranslation()
    const [selectedPlace, setSelectedPlace] = useState<string>("")
    const { register, handleSubmit, setValue, trigger, watch, formState: { errors } } = useForm<FormValues>()
    const startDate = watch("startDate")
    function handleSelectPlace(e: ChangeEvent<HTMLInputElement>) {
        setSelectedPlace(e.target.value)
    }

    const handleSubmitForm: SubmitHandler<FormValues> = e => {
        let objData = {
            projectName: e.projectName,
            startDate: e.startDate,
            endDate: e.endDate,
            projectDuration: e.duration,
            projectPlace: e.selectPlace,
            outdoorPart: e.outDoorPart ? e.outDoorPart : 0,
            energyPart: e.energyPart ? e.energyPart : 0,
            placeOther: e?.placeOther,
            projectSQm: e.sQm,
            projectUsageSQm: e.usageSQm,
            attendees: e.attendees,
            attendeesOnline: e.attendeesOnline ? e.attendeesOnline : 0,
            forigener: e.forigener ? e.forigener : 0,
            projectOwner: e.owner,
        }

        let tmpCreateDataStr = sessionStorage.getItem("tmpCreateData")
        let tmpCreateData = JSON.parse(tmpCreateDataStr!)
        tmpCreateData = Object.assign(tmpCreateData, objData)
        tmpCreateData.status = event
        tmpCreateData.paging = 3
        sessionStorage.setItem("tmpCreateData", JSON.stringify(tmpCreateData))
        navigate("/register/" + event + "/3")

    };

    useEffect(() => {
        let tmpCreateDataStr = sessionStorage.getItem("tmpCreateData")
        let tmpCreateData = JSON.parse(tmpCreateDataStr!)

        setValue("projectName", tmpCreateData.projectName)
        setValue("startDate", tmpCreateData.startDate)
        setValue("endDate", tmpCreateData.endDate)
        setValue("duration", tmpCreateData.projectDuration)
        setValue("selectPlace", tmpCreateData.projectPlace)
        setValue("placeOther", tmpCreateData?.placeOther ? tmpCreateData.placeOther : "")
        setValue("outDoorPart", tmpCreateData.outdoorPart ? tmpCreateData.outdoorPart : 0)
        setValue("energyPart", tmpCreateData.energyPart ? tmpCreateData.energyPart : 0)
        setValue("sQm", tmpCreateData.projectSQm)
        setValue("usageSQm", tmpCreateData.projectUsageSQm)
        setValue("attendees", tmpCreateData.attendees)
        setValue("attendeesOnline", tmpCreateData.attendeesOnline ? tmpCreateData.attendeesOnline : 0)
        setValue("forigener", tmpCreateData.forigener ? tmpCreateData.forigener : 0)
        setValue("owner", tmpCreateData.projectOwner ? tmpCreateData.projectName : "")
    }, [])

    useEffect(() => {
        const handleLanguageChange = () => {
            setTimeout(() => {
                trigger() // Re-validate the form to update error messages
            }, 50)
        };

        i18n.on('languageChanged', handleLanguageChange)

        return () => {
            i18n.off('languageChanged', handleLanguageChange)
        };
    }, [i18n, trigger])

    return (
        <form onSubmit={handleSubmit(handleSubmitForm)} action="#">
            <div className="columns is-moblie">
                <div className="column">
                    <br /><br />
                    <h1 className="content-header content-left">{t("FORM_PAGE.HEADER")}</h1>
                </div>
            </div>
            <div className="columns is-moblie">
                <div className="column is-4">
                    <div className="field">
                        <div className="control is-expanded">
                            <p className="content-left">{t("FORM_PAGE.NAME")}<span className="has-text-danger">*</span></p>
                            <input className="input" id={"setProjectName"} type="text"
                                style={{ borderRadius: "10px", borderColor: errors.projectName ? "red" : "", background: errors.projectName ? "" : "" }}
                                {...register('projectName', { required: t("ERROR_MSG_FORM.ERROR_FORM_PREFIX_MSG") + t("FORM_PAGE.NAME") + t("ERROR_MSG_FORM.ERROR_FORM_SUFFIX_MSG") })} />
                            {errors?.projectName ? <p className="content-error">{errors.projectName.message}</p> : <p className="content-error">&nbsp;</p>}
                        </div>
                    </div>
                </div>
                <div className="column is-3">
                    <div className="field">
                        <div className="control is-expanded">
                            <p className="content-left">{t("FORM_PAGE.START_DATE")}<span className="has-text-danger">*</span></p>
                            <input className="input" type="date" id="setStartDate"
                                style={{ borderRadius: "10px", borderColor: errors.startDate ? "red" : "", background: errors.startDate ? "" : "" }}
                                {...register('startDate', {
                                    required: t("ERROR_MSG_FORM.ERROR_FORM_SELECT_MSG") + t("FORM_PAGE.START_DATE") + t("ERROR_MSG_FORM.ERROR_FORM_SUFFIX_MSG")
                                })} />
                            {errors?.startDate ? <p className="content-error">{errors.startDate.message}</p> : <p className="content-error">&nbsp;</p>}
                        </div>
                    </div>
                </div>
                <div className="column is-3">
                    <div className="field">
                        <div className="control is-expanded">
                            <p className="content-left">{t("FORM_PAGE.END_DATE")}<span className="has-text-danger">*</span></p>
                            <input className="input" type="date" id="setEndDate"
                                style={{ borderRadius: "10px", borderColor: errors.endDate ? "red" : "", background: errors.endDate ? "" : "" }}
                                {...register('endDate', {
                                    required: t("ERROR_MSG_FORM.ERROR_FORM_SELECT_MSG") + t("FORM_PAGE.END_DATE") + t("ERROR_MSG_FORM.ERROR_FORM_SUFFIX_MSG"),
                                    validate: value => new Date(value) >= new Date(startDate) || t("ERROR_MSG_FORM.ERROR_FORM_DATE")
                                })} />
                            {errors?.endDate ? <p className="content-error">{errors.endDate.message}</p> : <p className="content-error">&nbsp;</p>}
                        </div>
                    </div>
                </div>
                <div className="column is-2">
                    <div className="field">
                        <div className="control is-expanded">
                            <p className="content-left">{t("FORM_PAGE.DURATION")}<span className="has-text-danger">*</span></p>
                            <input className="input" type="number" id="setDuration" step={0.1} min={0.1}
                                style={{ borderRadius: "10px", borderColor: errors.duration ? "red" : "", background: errors.duration ? "" : "" }} onKeyDown={blockInvalidChar}
                                {...register('duration', { required: t("ERROR_MSG_FORM.ERROR_FORM_PREFIX_MSG") + t("FORM_PAGE.DURATION") + t("ERROR_MSG_FORM.ERROR_FORM_SUFFIX_MSG"), valueAsNumber: true, min: { value: 0.1, message: t("FORM_PAGE.DURATION") + t("ERROR_MSG_FORM.ERROR_AMT_GT_MSG") + "0.1" } })} />
                            {errors?.duration ? <p className="content-error">{errors.duration.message}</p> : <p className="content-error">&nbsp;</p>}
                        </div>
                    </div>
                </div>
            </div>
            <div className="columns is-moblie">
                <div className={selectedPlace !== "อื่น ๆ" ? "column is-4" : "column is-2"}>
                    <div className="field">
                        <div className="control is-expanded">
                            <p className="content-left">{t("FORM_PAGE.SELECT_PLACE")}<span className="has-text-danger">*</span></p>
                            <div className="select is-fullwidth">
                                <select id="setSelectPlace" className="form-control"
                                    style={{ borderRadius: "10px", borderColor: errors.selectPlace ? "red" : "", background: errors.selectPlace ? "" : "" }}
                                    {...register('selectPlace', { required: t("ERROR_MSG_FORM.ERROR_FORM_SELECT_MSG") + t("FORM_PAGE.SELECT_PLACE") + t("ERROR_MSG_FORM.ERROR_FORM_SUFFIX_MSG"), onChange: (e) => handleSelectPlace(e) })} >
                                    {PlaceEvent.map(({ value, label }, index) => (
                                        <option key={index} value={value}>
                                            {t(label)}
                                        </option>
                                    ))}
                                </select>
                                {errors?.selectPlace ? <p className="content-error">{errors.selectPlace.message}</p> : <p className="content-error">&nbsp;</p>}
                            </div>
                        </div>
                    </div>
                </div>
                {selectedPlace === "อื่น ๆ" ?
                    <div className="column is-3">
                        <div className="field">
                            <div className="control is-expanded">
                                <p className="content-left">{t("FORM_PAGE.PLACE_OTHER")}<span className="has-text-danger">*</span></p>
                                <input className="input" type="text" id="setPlaceOther"
                                    style={{ borderRadius: "10px", borderColor: errors.placeOther ? "red" : "", background: errors.placeOther ? "" : "" }}
                                    {...register('placeOther', { required: t("ERROR_MSG_FORM.ERROR_FORM_PREFIX_MSG") + t("FORM_PAGE.PLACE_OTHER") + t("ERROR_MSG_FORM.ERROR_FORM_SUFFIX_MSG") })} />
                                {errors?.placeOther ? <p className="content-error">{errors.placeOther.message}</p> : <p className="content-error">&nbsp;</p>}
                            </div>
                        </div>
                    </div> : ""
                }
                <div className="column is-4">
                    <div className="field">
                        <div className="control is-expanded">
                            <p className="content-left">{t("FORM_PAGE.OUTDOOR_PART")}</p>
                            <input className="input" type="number" id="setOutDoorPart" step={0.01} defaultValue={0} min={0} style={{ borderRadius: "10px" }} onKeyDown={blockInvalidChar}
                                {...register('outDoorPart', { valueAsNumber: true, min: { value: 0, message: t("FORM_PAGE.OUTDOOR_PART") + t("ERROR_MSG_FORM.ERROR_AMT_GT_MSG") + "0" } })} />
                        </div>
                    </div>
                </div>
                <div className="column is-3">
                    <div className="field">
                        <div className="control is-expanded">
                            <p className="content-left">{t("FORM_PAGE.ENERGY_PART")}</p>
                            <input className="input" type="number" id="setEnergyPart" step={0.01} defaultValue={0} min={0} style={{ borderRadius: "10px" }} onKeyDown={blockInvalidChar}
                                {...register('energyPart', { valueAsNumber: true, min: { value: 0, message: t("FORM_PAGE.ENERGY_PART") + t("ERROR_MSG_FORM.ERROR_AMT_GT_MSG") + "0" } })} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="columns is-moblie">
                <div className="column is-2">
                    <div className="field">
                        <div className="control is-expanded">
                            <p className="content-left">{t('FORM_PAGE.SQM')}<span className="has-text-danger">*</span></p>
                            <input className="input" type="number" id="setSQm" step={0.01} min={0.01}
                                style={{ borderRadius: "10px", borderColor: errors.sQm ? "red" : "", background: errors.sQm ? "" : "" }} onKeyDown={blockInvalidChar}
                                {...register('sQm', { required: t("ERROR_MSG_FORM.ERROR_FORM_PREFIX_MSG") + t("FORM_PAGE.SQM") + t("ERROR_MSG_FORM.ERROR_FORM_SUFFIX_MSG"), valueAsNumber: true, min: { value: 0.01, message: t("FORM_PAGE.SQM") + t("ERROR_MSG_FORM.ERROR_AMT_GT_MSG") + "0.01" } })} />
                            {errors?.sQm ? <p className="content-error">{errors.sQm.message}</p> : <p className="content-error">&nbsp;</p>}
                        </div>
                    </div>
                </div>
                <div className="column is-2">
                    <div className="field">
                        <div className="control is-expanded">
                            <p className="content-left">{t("FORM_PAGE.USAGE_SQM")}<span className="has-text-danger">*</span></p>
                            <input className="input" type="number" id="setUsageSQm" step={0.01} min={0.01}
                                style={{ borderRadius: "10px", borderColor: errors.usageSQm ? "red" : "", background: errors.usageSQm ? "" : "" }} onKeyDown={blockInvalidChar}
                                {...register('usageSQm', { required: t("ERROR_MSG_FORM.ERROR_FORM_PREFIX_MSG") + t("FORM_PAGE.USAGE_SQM") + t("ERROR_MSG_FORM.ERROR_FORM_SUFFIX_MSG"), valueAsNumber: true, min: { value: 0.01, message: t("FORM_PAGE.USAGE_SQM") + t("ERROR_MSG_FORM.ERROR_AMT_GT_MSG") + "0.01" } })} />
                            {errors?.usageSQm ? <p className="content-error">{errors.usageSQm.message}</p> : <p className="content-error">&nbsp;</p>}
                        </div>
                    </div>
                </div>
                <div className="column is-4">
                    <div className="field">
                        <div className="control is-expanded">
                            <p className="content-left">{t("FORM_PAGE.ATTENDEES")}<span className="has-text-danger">*</span></p>
                            <input className="input" type="number" id="setAttendees" min={1}
                                style={{ borderRadius: "10px", borderColor: errors.attendees ? "red" : "", background: errors.attendees ? "" : "" }} onKeyDown={blockInvalidChar}
                                {...register('attendees', { required: t("ERROR_MSG_FORM.ERROR_FORM_PREFIX_MSG") + t("FORM_PAGE.ATTENDEES") + t("ERROR_MSG_FORM.ERROR_FORM_SUFFIX_MSG"), valueAsNumber: true, min: { value: 1, message: t("FORM_PAGE.ATTENDEES") + t("ERROR_MSG_FORM.ERROR_AMT_GT_MSG") + "1" } })} />
                            {errors?.attendees ? <p className="content-error">{errors.attendees.message}</p> : <p className="content-error">&nbsp;</p>}
                        </div>
                    </div>
                </div>
                <div className="column is-4">
                    <div className="field">
                        <div className="control is-expanded">
                            <p className="content-left">{t("FORM_PAGE.ATTENDEES_ONLINE")}</p>
                            <input className="input" type="number" id="setAttendeesOnline" defaultValue={0} min={0} style={{ borderRadius: "10px" }} onKeyDown={blockInvalidChar}
                                {...register('attendeesOnline')} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="columns">
                <div className="column">
                    <div className="field">
                        <div className="control is-expanded">
                            <p className="content-left">{t("FORM_PAGE.FORIGENER")}</p>
                            <input className="input" type="number" id="setForigener" step={0.01} defaultValue={0} min={0} style={{ borderRadius: "10px" }} onKeyDown={blockInvalidChar}
                                {...register('forigener')} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="columns">
                <div className="column">
                    <div className="field">
                        <div className="control is-expanded">
                            <p className="content-left">{t("FORM_PAGE.OWNER")}<span className="has-text-danger">*</span></p>
                            <textarea className="textarea" id="setOwner"
                                style={{ borderRadius: "10px", borderColor: errors.owner ? "red" : "", background: errors.owner ? "" : "" }}
                                {...register('owner', { required: t("ERROR_MSG_FORM.ERROR_FORM_PREFIX_MSG") + t("FORM_PAGE.OWNER") + t("ERROR_MSG_FORM.ERROR_FORM_SUFFIX_MSG") })} />
                            {errors?.owner ? <p className="content-error">{errors.owner.message}</p> : <p className="content-error">&nbsp;</p>}
                        </div>
                    </div>
                </div>
            </div>
            <div className="columns">
                <div className="column is-4 is-offset-4" style={{ alignItems: "right" }}>
                    <br />
                    <button className="button is-rounded is-large is-fullwidth is-success App" type="submit">{t("BUTTONS.NEXT")}</button>
                </div>
            </div>
        </form>
    )
}
export default MyForm;