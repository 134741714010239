import { Document, Page, PDFViewer, Text, View, Image, Font } from '@react-pdf/renderer';
import StyleSheet from './StyleSheet';
import { TemplateType } from '.';

const Template = ({
  imageBackground,
  dataTemplate,
}: {
  dataTemplate: TemplateType;
  imageBackground: string;
}) => {
  return (
    <Document>
      <Page size="A5" style={StyleSheet.page}>
        <Image src={imageBackground} style={StyleSheet.pageBackground} />
        <View style={StyleSheet.positionContent}>
          <View
            style={{
              maxWidth: 380,
              padding: 5,
              textAlign: 'center',
            }}
          >
            <Text style={StyleSheet.textContent}>{dataTemplate?.content.split('')}</Text>
          </View>
        </View>

        <View style={StyleSheet.positionCredit}>
          <Text style={StyleSheet.textCredit}>{dataTemplate?.num_credit}</Text>
        </View>
      </Page>
    </Document>
  );
};

export default Template;
