import React from 'react';
import { Route, Navigate, RouteProps, useLocation } from 'react-router-dom';

interface ProtectedRouteProps {
  component: React.ComponentType;
  allowedRoles: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
  allowedRoles,
  ...rest
}) => {
  return <Component />;
};

export default ProtectedRoute;
