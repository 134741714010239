import { useQuery } from '@tanstack/react-query';
import api from '../axios';

export const useEventsQuery = (isEvent: string, page: number, limit: number) => {
  return useQuery({
    queryKey: ['events', isEvent, page, limit],
    queryFn: async ({ queryKey }: { queryKey: [string, string, number, number] }) => {
      const [, isEvent, page, limit] = queryKey;
      try {
        const { data } = await api.get(`/events?isEvent=${isEvent}&page=${page}&limit=${limit}`);
        return data;
      } catch (error) {
        throw error;
      }
    },
  });
};

export const usePreEventsQuery = (
  subCategoryId: string,
  year: string,
  quarter: string,
  search: string,
  page: number,
  limit: number
) => {
  return useQuery({
    queryKey: ['events', subCategoryId, year, quarter, search, page, limit],
    queryFn: async ({
      queryKey,
    }: {
      queryKey: [string, string, string, string, string, number, number];
    }) => {
      const [, subCategoryId, year, quarter, search, page, limit] = queryKey;
      try {
        const { data } = await api.get(
          `/events/pre-event?subCategoryId=${subCategoryId}&year=${year}&quarter=${quarter}&search=${search}&limit=${limit}`
        );
        return data;
      } catch (error) {
        throw error;
      }
    },
  });
};

export const useEventsByIdQuery = (id: number | string) => {
  return useQuery({
    queryKey: ['eventsId', id],
    queryFn: async ({ queryKey }: { queryKey: [string, number | string] }) => {
      const [, id] = queryKey;
      try {
        const { data } = await api.get(`/events/${id}`);
        return data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  });
};
