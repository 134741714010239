import { Grid } from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import SelectZero from '@admin/components/select-zero';
import { HeaderZero } from '@admin/components/header-zero';
import { TableZeroCarbon } from '@admin/components/TableZeroCarbon';
import WidgetSummary from '@admin/components/widget-summary';
import PostEventTable from './post-event-table';

interface PostEventViewProps {
  title?: string;
}

const PostEventViewSection = ({ title }: PostEventViewProps) => {
  const filterElement = (
    <>
      {/* <SelectZero label="ประเภทโครงการ" placeholder="ทุกประเภท" />
      <SelectZero label="ปี" placeholder="ทุกปี" />
      <SelectZero label="ไตรมาส" placeholder="ทุกไตรมาส" /> */}
    </>
  );

  const widgetSummaryProps = [
    {
      title: 'รอชำระเงิน',
      value: 1000,
      type: 'กิจกรรม',
      detail: `16,590 (ยอดสุทธิ 16,320.17) บาท`,
    },
    {
      title: 'ชำระเงินแล้ว',
      value: 1000,
      type: 'กิจกรรม',
      detail: `16,590 (ยอดสุทธิ 16,320.17) บาท`,
    },
    {
      title: 'รอชดเชย GHG',
      value: 1000,
      type: 'กิจกรรม',
      detail: `16,590 (ยอดสุทธิ 16,320.17) บาท`,
    },
    {
      title: 'ชดเชย GHG แล้ว',
      value: 1000,
      type: 'กิจกรรม',
      detail: `16,590 (ยอดสุทธิ 16,320.17) บาท`,
    },
  ];

  return (
    <>
      <Helmet>
        <title> {title ?? ''} | Zero Carbon </title>
      </Helmet>
      <HeaderZero title={title ?? ''} filterElement={filterElement} />

      <Grid container spacing={3} mb={2}>
        {widgetSummaryProps.map((item, index) => (
          <Grid key={index} xs={12} sm={3} md={3} item>
            <WidgetSummary {...item} />
          </Grid>
        ))}
      </Grid>

      <PostEventTable />
    </>
  );
};

export default PostEventViewSection;
