import { Box, Container, Grid } from '@mui/material';
import React, { useEffect, useState, MouseEvent } from 'react';
import WidgetSummary from '@src/component/layout/admin/components/widget-summary';
import WidgetChartBar from '@src/component/layout/admin/components/widget-chartBar';
import Select from '@src/component/layout/admin/components/select-zero';
import { HeaderZero } from '@src/component/layout/admin/components/header-zero';
import { useSubCategories } from '@src/services/queries/useCategoriesQuery';
import { optionYear } from '@src/utils/option-year';
import { useOverview } from '@src/services/queries/useDashboardQuery';
import SkeletonOverview from '@src/component/layout/admin/components/Element/skeletonOverview';

const DashboardPage = () => {
  const { data } = useSubCategories();

  const newSubCategories = data?.data?.map((item: any) => {
    return { label: item.name, value: item.id };
  });
  const [projectType, setProjectType] = useState('');
  const [years, setYears] = useState('');
  const [quarter, setQuarter] = useState('');

  const overviewQuery = useOverview();

  const $items = [
    {
      name: 'ผู้ใช้งานทั้งหมด',
      value: overviewQuery?.data?.userCount ?? 0,
      type: 'คน',
    },
    {
      name: 'Pre-event ทั้งหมด',
      value: overviewQuery?.data?.eventCount ?? 0,
      type: 'กิจกรรม',
    },
    {
      name: 'Post-event ทั้งหมด',
      value: overviewQuery?.data?.postEventCount ?? 0,
      type: 'กิจกรรม',
    },
    {
      name: 'โครงการทั้งหมด',
      value: overviewQuery?.data?.eventCount ?? 0,
      type: 'โครงการ',
    },
  ];
  return (
    <>
      <HeaderZero
        title="ภาพรวมผู้ใช้งาน"
        filterElement={
          <>
            <Select
              label="ประเภทโครงการ"
              placeholder="ทุกประเภท"
              setValue={setProjectType}
              options={newSubCategories}
            />
            <Select label="ปี" placeholder="ทุกปี" setValue={setYears} options={optionYear()} />
            <Select
              label="ไตรมาส"
              placeholder="ทุกไตรมาส"
              setValue={setQuarter}
              options={[
                { label: 'เดือน 1', value: '1' },
                { label: 'เดือน 2', value: '2' },
                { label: 'เดือน 3', value: '3' },
                { label: 'เดือน 4', value: '4' },
              ]}
            />
          </>
        }
      />

      {overviewQuery.isLoading && <SkeletonOverview />}

      {overviewQuery.isSuccess && (
        <>
          <Grid container spacing={3} mb={2}>
            <Grid xs={12} sm={6} md={6} item>
              <WidgetSummary
                title={'จำนวนคาร์บอนฟุตพริ้นท์ทั้งหมด'}
                value={overviewQuery?.data?.totalCarbonFootPrint}
                type={'ตัน'}
              />
            </Grid>

            <Grid xs={12} sm={6} md={6} item>
              <WidgetSummary
                title={'จำนวนคาร์บอนฟุตพริ้นทที่ชดเชยแล้ว'}
                value={overviewQuery?.data?.totalCompensatedCarbonFootPrint}
                type={'ตัน'}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            {$items.map((item, index) => (
              <Grid xs={12} sm={6} md={3} key={index} item>
                <WidgetSummary title={item.name} value={item.value} type={item.type} />
              </Grid>
            ))}

            <Grid xs={12} sm={12} md={6} item>
              <WidgetChartBar
                title="สรุปจำนวนกิจกรรม"
                subTitle="แยกตามประเภท"
                labels={overviewQuery?.data?.categoriesCountByEvent.label ?? []}
                data={overviewQuery?.data?.categoriesCountByEvent.value ?? []}
                sx={{ mt: 2 }}
              />
            </Grid>
            <Grid xs={12} sm={12} md={6} item>
              <WidgetChartBar
                title=" สรุปปริมาณคาร์บอนเครดิตที่ชดเชย"
                subTitle="ตามปีงบประมาณ"
                labels={overviewQuery?.data?.dashboarReceiveCreditSpend.label ?? []}
                data={overviewQuery?.data?.dashboarReceiveCreditSpend.value ?? []}
                sx={{ mt: 2 }}
              />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default DashboardPage;
