import { StyleSheet } from '@react-pdf/renderer';

const Style = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'row',
  },
  pageBackground: {
    position: 'relative',
    minWidth: '100%',
    minHeight: '100%',
    // height: '100%',
    width: '100%',
  },

  positionContent: {
    position: 'absolute',
    // width: '100%',
    marginTop: 270,
    marginLeft: 20,

    paddingLeft: 45,
    paddingRight: 25,
  },
  textContent: {
    fontSize: 13,
    maxWidth: 370,

    fontWeight: 200,
    // wordWrap: 'break-word',
    fontFamily: 'THSan',
    color: '#03346E',
    display: 'flex',
  },

  positionCredit: {
    position: 'absolute',
    width: '100%',
    bottom: 5,
    left: 20,
  },
  textCredit: {
    fontSize: 12,
    fontWeight: 200,
    fontFamily: 'THSan',
  },
});
export default Style;
