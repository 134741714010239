import Dashboard from '../pages/Dashboard';
import Register from '../pages/Register';
import Login from '../pages/Login';
import AdminDashboardPage from '../pages/admin/Dashboard';
import PreEventPage from '../pages/admin/PreEvent';
import PostEventPage from '../pages/admin/PostEvent';
import ProjectPage from '../pages/admin/Project';
import UsersPage from '../pages/admin/Users';
import PreEventViewPage from '../pages/admin/PreEvent/view';
import PostEventViewPage from '@src/pages/admin/PostEvent/view';

import React from 'react';
import { Routes, Route, Navigate, Routes as ReactRoutes } from 'react-router-dom';
import AdminLayout from '../component/layout/AdminLayout';
import NotFoundPage from '../pages/NotFound';
import UserLayout from '../component/layout/UserLayout';
import ProtectedRoute from './ProtectedRoute';

const routerAdmin = [
  { path: '/admin', component: AdminDashboardPage },
  { path: '/admin/pre-event', component: PreEventPage },
  { path: '/admin/pre-event/:id', component: PreEventViewPage },
  { path: '/admin/post-event', component: PostEventPage },
  { path: '/admin/post-event/:id', component: PostEventViewPage },
  { path: '/admin/project', component: ProjectPage },
  { path: '/admin/users', component: UsersPage },
];
const AdminRoutes = () => (
  <ReactRoutes>
    <Route element={<AdminLayout />}>
      {routerAdmin.map(({ path, component }) => (
        <Route
          key={path}
          path={path}
          element={<ProtectedRoute component={component} allowedRoles="admin" />}
        />
      ))}
      <Route element={<ProtectedRoute component={NotFoundPage} allowedRoles="admin" />} />
    </Route>
    <Route path="*" element={<Navigate to="/admin" replace />} />
  </ReactRoutes>
);
const PublicRoutes = () => (
  <Routes>
    <Route element={<UserLayout />}>
      <Route path="/" element={<ProtectedRoute component={Dashboard} allowedRoles={'user'} />} />
      <Route
        path="/register/:event/:id"
        element={<ProtectedRoute component={Register} allowedRoles={'user'} />}
      />
      <Route element={<ProtectedRoute component={NotFoundPage} allowedRoles={'user'} />} />
    </Route>
    <Route path="/login" element={<Login />} />
    <Route path="*" element={<Navigate to="/" replace />} />
  </Routes>
);

export { AdminRoutes, PublicRoutes };
