import React from 'react';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
} from '@mui/material';

import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
interface ContentsDialogProps {
  open: boolean;
  handleClose: () => void;
  onSubmit: (data: any) => void;
  defaultValue?: string;
}
const schema = z.object({
  content: z.string().nonempty({ message: 'กรุณากรอกข้อมูลให้ครบถ้วน' }),
});
export type FormContentsData = z.infer<typeof schema>;
const ContentsDialog: React.FC<ContentsDialogProps> = ({
  open,
  handleClose,
  onSubmit,
  defaultValue,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormContentsData>({
    resolver: zodResolver(schema),
    defaultValues: {
      content: defaultValue,
    },
  });
  return (
    <Dialog fullWidth open={open} onClose={handleClose}>
      <DialogTitle>{'แก้ไขข้อความ'}</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <TextField
            multiline
            rows={4}
            label="ข้อความ"
            {...register('content')}
            error={!!errors.content}
            helperText={errors.content?.message}
            fullWidth
            margin="normal"
            inputProps={{ borderRadius: '8px' }}
          />
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button
            onClick={handleClose}
            color="inherit"
            sx={{ borderRadius: '8px', alignItems: 'center' }}
            variant="contained"
          >
            ปิดหน้าต่าง
          </Button>
          <Button
            type="submit"
            variant="contained"
            sx={{ borderRadius: '8px', alignItems: 'center' }}
          >
            <AssignmentTurnedInIcon />
            ยันยัน
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ContentsDialog;
