export const formatDate = (date: string, showTime?: boolean) => {
  if (!date) return;
  const d = new Date(date);
  const day = d.getDate();
  const month = d.getMonth() + 1;
  const year = d.getFullYear();
  const hour = d.getHours();
  const minute = d.getMinutes();

  let result = `${day} ${['', 'ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'][month]} ${year}`;
  if (showTime) {
    result += ` (${hour < 10 ? '0' + hour : hour}:${minute < 10 ? '0' + minute : minute} )`;
  }
  return result;
};
