import React, { MouseEvent, useCallback, useEffect, useState } from 'react';
import Logo from './constants/img/logo2.png';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import Routing from './routes';
import { ToastContainer } from 'react-toastify';
import './App.css';
import { AuthProvider } from './context/AuthContext';
import { useTranslation } from 'react-i18next';
import { LANGUAGES } from './constants/screen/dropdownList';
import ReactCountryFlag from 'react-country-flag';
import { AdminRoutes } from './routes/routes';
import GlobeHemisphereWest from './constants/img/GlobeHemisphereWest.png';
import { keyNameCookie } from './pages/Authentication';
import { useAuthGeneralToken, useAuthToken } from './services/queries/useAuthQuery';
//@ts-ignore
import Cookies from 'js-cookie';

//import { HelmetProvider } from 'react-helmet-async';

function App() {
  const { t, i18n } = useTranslation();
  const pages = [''];
  const settings = [''];
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const { data, isLoading } = useAuthToken();
  const handleOpenNavMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const onChangeLang = (e: MouseEvent<HTMLElement>) => {
    const { id } = e.currentTarget;
    i18n.changeLanguage(id);
  };

  if (isLoading) return <div>Loading...</div>;
  return (
    <>
      {data?.role == 'ADMIN' ? (
        <AdminRoutes />
      ) : (
        <div className="App">
          <ToastContainer style={{ width: '400px' }} pauseOnFocusLoss={false} newestOnTop />
          <AppBar position="static" color="default">
            <Container maxWidth="xl">
              <Toolbar disableGutters>
                <img src={Logo} alt="Logo" width="100" />
                <Typography
                  variant="h6"
                  noWrap
                  component="a"
                  href="/"
                  sx={{
                    mr: 2,
                    ml: 6,
                    display: { xs: 'none', md: 'flex' },
                    fontWeight: 700,
                    fontFamily: 'Kanit, sans-serif',
                    fontSize: 'x-large',
                    color: '#106DBE',
                    textDecoration: 'none',
                  }}
                >
                  Zero Carbon Platform
                </Typography>
                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                    color="inherit"
                  >
                    <MenuIcon />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{
                      display: { xs: 'block', md: 'none' },
                    }}
                  >
                    {pages?.map((page) => (
                      <MenuItem key={page} onClick={handleCloseNavMenu}>
                        <Typography textAlign="center">{page}</Typography>
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
                <Typography
                  variant="h6"
                  noWrap
                  component="a"
                  href="/"
                  sx={{
                    mr: 2,
                    ml: 2,
                    display: { xs: 'flex', md: 'none' },
                    flexGrow: 1,
                    fontFamily: 'Kanit, sans-serif',
                    fontSize: 'x-large',
                    color: '#106DBE',
                    fontWeight: 700,
                    textDecoration: 'none',
                  }}
                >
                  Zero Carbon Platform
                </Typography>
                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                  {pages.map((page) => (
                    <Button
                      key={page}
                      onClick={handleCloseNavMenu}
                      sx={{ my: 2, color: 'white', display: 'block' }}
                    >
                      {page}
                    </Button>
                  ))}
                </Box>

                <Box sx={{ flexGrow: 0 }}>
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <img src={GlobeHemisphereWest}></img>
                  </IconButton>

                  <Menu
                    sx={{ mt: '45px' }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    {LANGUAGES?.map(({ code, label, flag }) => (
                      <MenuItem key={code} value={code} id={code} onClick={onChangeLang}>
                        <div
                          className="column"
                          style={{ marginTop: '-15px', marginBottom: '-10px' }}
                        >
                          <p className="content-left">{label}</p>
                        </div>
                        <div
                          className="column content-right"
                          style={{ marginTop: '-15px', marginBottom: '-10px' }}
                        >
                          <ReactCountryFlag
                            countryCode={flag}
                            svg
                            style={{ width: '1.5em', height: '1.5em' }}
                            title={flag}
                          />
                        </div>
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
              </Toolbar>
            </Container>
          </AppBar>
          <AuthProvider>
            <Routing />
          </AuthProvider>
        </div>
      )}
    </>
  );
}

export default App;
