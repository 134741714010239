import React, { useState } from 'react';
import { emptyRows, applyFilter, getComparator } from '@admin/components/TableZeroCarbon/utils';
import Scrollbar from '@src/component/scrollbar';
import {
  Button,
  Card,
  Checkbox,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
} from '@mui/material';
import TableHeadZero from '@admin/components/TableZeroCarbon/TableHead';
import TableEmptyRows from '@admin/components/TableZeroCarbon/TableEmptyRows';
import TableToolBar from '@admin/components/TableZeroCarbon/TableToolBar';
import { formatDate } from '@src/utils/format-date-th';
import { useNavigate } from 'react-router';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import TableRowZeroCarbon from '../../../components/TableZeroCarbon/TableRow';
import Iconify from '../../../components/iconify';
import { useEventsQuery } from '@src/services/queries/useEventsQuery';
import { EventEmission } from '@src/types';
import TableLoading from '../../../components/TableZeroCarbon/TableLoading';
import CompensateStatusBadge from '../../../components/Element/CompensateStatusBadge';
import cn from '@src/utils/class-names';

const PostEventTable = () => {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState<any>([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(20);
  const navigate = useNavigate();

  const { data, isLoading } = useEventsQuery('POST_EVENT', page + 1, rowsPerPage);

  const handleSort = (event: any, id: any) => {
    const isAsc = orderBy === id && order === 'asc';
    if (id !== '') {
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(id);
    }
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: object) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: any = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilter = (event: any) => {
    setPage(0);
    setFilterName(event);
  };

  const postEvents = data?.data || [];

  const dataFiltered = applyFilter({
    inputData: postEvents,
    comparator: getComparator(order, orderBy),
    filterName,
  });

  const handleView = (id: number | undefined) => {
    navigate(`/admin/post-event/${id}`);
  };

  const TableRowBody = ({
    index,
    rows,
    handleClick,
    selected,
  }: {
    index: number;
    rows: EventEmission;
    handleClick: (arg: any) => void;
    selected: boolean | undefined;
  }) => {
    const { id, name, location, startDate, endDate, emission, paymentStatus, compensateStatus } =
      rows;

    return (
      <>
        <TableCell padding="checkbox">
          <Checkbox
            disableRipple
            sx={{ width: 12, p: 0 }}
            checked={selected}
            onChange={handleClick}
          />
        </TableCell>
        <TableCell
          sx={{
            textAlign: 'center',
          }}
        >
          {index + 1}
        </TableCell>

        <TableCell scope="row">
          <div className="truncate max-w-72 flex flex-col" title={name}>
            {name}
            <span className="text-gray-500 font-extralight">{location}</span>
          </div>
        </TableCell>
        <TableCell sx={{ textAlign: 'center' }}>
          <div className="text-center">{emission?.activity_type?.name}</div>
        </TableCell>
        <TableCell>
          {' '}
          {emission?.summary_person ? emission?.summary_person.toFixed(2) : 0}{' '}
        </TableCell>
        <TableCell>{emission?.summary ? emission?.summary.toFixed(2) : 0}</TableCell>

        <TableCell>
          {' '}
          {formatDate(startDate)}
          {' - '}
          {formatDate(endDate)}
        </TableCell>
        <TableCell align="center">
          <span
            className={cn(
              'text-emerald-400 font-semibold',
              paymentStatus === 'COMPLETE'
                ? 'text-emerald-500'
                : paymentStatus === 'ON_HOLD'
                  ? 'text-orange-500'
                  : paymentStatus === 'CANCEL'
                    ? 'text-black'
                    : paymentStatus === 'FAIL'
                      ? 'text-red-500'
                      : paymentStatus === 'INCOMPLETE'
                        ? 'text-cyan-500'
                        : 'text-gray-700'
            )}
          >
            {paymentStatus}
          </span>
        </TableCell>
        <TableCell align="center">
          <div className="flex justify-center">
            <CompensateStatusBadge compensateStatus={compensateStatus} />
          </div>
        </TableCell>

        <TableCell align="right">
          <IconButton aria-label="delete" size="small">
            <VisibilityOutlinedIcon onClick={() => handleView(id)} />
          </IconButton>
        </TableCell>
      </>
    );
  };
  return (
    <>
      <TableToolBar
        numSelected={selected}
        ButtonExport={
          <Button
            variant="contained"
            sx={{
              color: 'black',
              borderLeftRadius: '10px',

              backgroundColor: '#8edd72',
              '&:hover': {
                backgroundColor: '#69b54c',
              },
            }}
          >
            <Iconify icon="vscode-icons:file-type-excel2" /> Export
          </Button>
        }
        onSearch={handleFilter}
      />
      <Scrollbar>
        <Card>
          <TableContainer component={Paper}>
            <Table
              sx={{
                minWidth: {
                  sm: 1700,
                  md: 1600,
                  lg: 1300,
                },
              }}
              size={'small'}
            >
              <colgroup>
                <col width="1%" />
                <col width="1%" />
                <col width="16%" />
                <col width="10%" />
                <col width="14%" />
                <col width="20%" />
                <col width="9%" />
                <col width="11%" />
                <col width="9%" />
                <col width="10%" />
              </colgroup>
              <TableHeadZero
                defaultChecked={true}
                order={order}
                orderBy={orderBy}
                rowCount={postEvents.length}
                numSelected={selected.length}
                onRequestSort={handleSort}
                headLabel={[
                  { id: '', label: 'ลำดับ', align: 'center' },
                  { id: 'name', label: 'ชื่อกิจกรรม' },
                  { id: 'type', label: 'ประเภทกิจกรรม', align: 'center' },
                  { id: 'outdoor_area', label: 'จำนวนผู้ร่วม (คน/วัน)' },
                  { id: 'total_cf', label: 'ปริมาณคาร์บอนฟุตพริ้นท์รวม (ตัน)	' },
                  { id: 'start_date', label: 'วันที่จัดกรรม', align: 'center' },
                  { id: 'payment_status', label: 'สถานะการชำระเงิน', align: 'center' },
                  { id: 'compensate_status', label: 'สถานะชดเชย' },
                  { id: 'tools', label: 'เครื่องมือ' },
                ]}
              />
              <TableBody sx={{ fontSize: '0.75rem' }}>
                {isLoading && <TableLoading />}

                {dataFiltered
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRowZeroCarbon
                      rows={row}
                      index={index}
                      key={index}
                      TableRowBody={
                        <TableRowBody
                          key={row.id}
                          index={index}
                          selected={selected.indexOf(row.id) !== -1}
                          rows={row}
                          handleClick={(event: any) => handleClick(event, row.id)}
                        />
                      }
                    />
                  ))}

                <TableEmptyRows
                  height={77}
                  emptyRows={emptyRows(page, rowsPerPage, postEvents.length)}
                />
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Scrollbar>

      <TablePagination
        count={data?.total || 0}
        onPageChange={handleChangePage}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[10, 25]}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default PostEventTable;
