import { ReactNode, useEffect } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import { alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import ListItemButton from '@mui/material/ListItemButton';

// import Logo from "../../../constants/img/logo2.png";
import Logo from '@constants/img/logo-app.png';

import { NAV } from './config-layout';
import navConfig from './config-navigation';
import { useResponsive } from '../../../hooks/use-responsive';
import CustomLink from './components/router-link';
import { usePathname } from '../../../hooks';
import Scrollbar from '../../scrollbar';
import PersonIcon from '@mui/icons-material/Person';
import { useNavigate } from 'react-router';
// ----------------------------------------------------------------------
interface Props {
  openNav: boolean; // Define the type of onOpenNav
  onCloseNav: () => void; // Define the type of onOpenNav
}
export default function Nav({ openNav, onCloseNav }: Props) {
  const pathname = usePathname();

  const upLg = useResponsive('up', 'lg', 'sm');

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderAccount = (
    <Box
      sx={{
        py: 2,
        display: 'flex',
        borderRadius: 1.5,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          width: '100px',
        }}
      >
        <Avatar
          sx={{ m: 1, width: '100%', height: '100%' }}
          src={Logo}
          variant="square"
          alt="photoURL"
        ></Avatar>
      </div>
    </Box>
  );

  const renderMenu = (
    <Stack component="nav" spacing={0.5} sx={{ px: 0 }}>
      {navConfig.map((item) => (
        <NavItem key={item.title} item={item} />
      ))}
    </Stack>
  );

  const renderLogout = (
    <Stack
      alignItems="center"
      spacing={3}
      sx={{ px: 3, pt: 3, borderRadius: 5, position: 'relative' }}
    >
      <Button href="/login" variant="outlined" fullWidth size="large" color="success">
        ออกจากระบบ
      </Button>
    </Stack>
  );

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      {renderAccount}

      {renderMenu}

      <Box sx={{ flexGrow: 1 }} />
      {renderLogout}
    </Scrollbar>
  );

  return (
    <Box
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.WIDTH },
      }}
    >
      {upLg ? (
        <Box
          sx={{
            height: 1,
            position: 'fixed',
            width: NAV.WIDTH,
            backgroundColor: '#ffff',
            borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          }}
        >
          {renderContent}
        </Box>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              width: NAV.WIDTH,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}

// ----------------------------------------------------------------------
interface item {
  item: { title: string; path: string; icon: ReactNode };
}
function NavItem({ item }: item) {
  const navigate = useNavigate();
  const pathname = usePathname();
  function handleClick(path: string) {
    navigate(path);
  }
  const active = item.path === pathname;
  return (
    <ListItemButton
      onClick={() => handleClick(item.path)}
      sx={{
        minHeight: 35,
        borderRadius: 0.75,
        typography: 'body2',
        color: 'text.dark',
        textTransform: 'capitalize',
        fontWeight: 'fontWeightMedium',
        ...(active && {
          color: '#1AA238',
          fontWeight: 'fontWeightSemiBold',
          bgcolor: (theme) => alpha(theme.palette.grey[600], 0.11),
          '&:hover': {
            bgcolor: (theme) => alpha(theme.palette.grey[200], 0.16),
          },
        }),
      }}
    >
      <Box component="span" sx={{ width: 20, height: 20, mr: 2 }}>
        {item.icon}
      </Box>

      <Box component="span">{item.title} </Box>
    </ListItemButton>
  );
}
