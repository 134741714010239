import cn from '@src/utils/class-names';
import React, { useState } from 'react';
import { fileEn, fileTh, TemplateType } from '.';
import CreditDialog from './credit-dialog';
import ContentsDialog from './content-dialog';
const btnStyle = `flex items-center justify-center gap-2 rounded shadow-md  bg-gradient-to-r border absolute top-24 right-0 m-4
w-full max-w-44  py-2 px-5 from-navy-500 to-navy-500
hover:to-navy-400 border-navy-600 text-white`;
type EditToolsBarProps = {
  setDataTemplate: (arg: TemplateType) => void;
  setFileTemplate: (arg: string) => void;
  fileTemplate: string;
  dataTemplate: TemplateType;
};
const EditToolsBar = ({
  dataTemplate,
  setDataTemplate,
  fileTemplate,
  setFileTemplate,
}: EditToolsBarProps) => {
  const onHandleChangeTemplate = () => {
    if (fileTemplate !== fileEn) {
      setFileTemplate(fileEn);
    } else {
      setFileTemplate(fileTh);
    }
  };

  const [open, setOpen] = useState(false);
  const [openCredit, setOpenCredit] = useState(false);

  const handleClickOpenContent = () => {
    setOpen(true);
  };

  const handleClickOpenCredit = () => {
    setOpenCredit(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmitCredit = (data: any) => {
    if (data) {
      setDataTemplate({
        num_credit: data?.credit as string,
        content: dataTemplate.content,
      });
      setOpenCredit(false);
      setOpen(false);
    }
  };

  const onSubmitContent = (data: any) => {
    if (data) {
      setDataTemplate({
        num_credit: dataTemplate.num_credit,
        content: data?.content as string,
      });
      setOpen(false);
    }
  };
  return (
    <>
      <CreditDialog
        open={openCredit}
        handleClose={() => setOpenCredit(false)}
        defaultValue={dataTemplate.num_credit}
        onSubmit={onSubmitCredit}
      />
      <ContentsDialog
        open={open}
        handleClose={handleClose}
        onSubmit={onSubmitContent}
        defaultValue={dataTemplate.content}
      />
      <button className={cn(btnStyle, 'top-0')} onClick={handleClickOpenCredit}>
        แก้ไขเลขที่เครดิต
      </button>
      <button className={cn(btnStyle, 'top-12')} onClick={handleClickOpenContent}>
        แก้ไขข้อความ
      </button>
      <button className={btnStyle} onClick={onHandleChangeTemplate}>
        เปลี่ยนภาษาเนื้อหา
      </button>
    </>
  );
};

export default EditToolsBar;
