import React, { RefCallback, MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

interface propsPolicyRefunds {
    open: boolean;
    handleClose: RefCallback<any>;
}


export default function PolicyRefunds(props: propsPolicyRefunds) {
    const { t } = useTranslation();
    const { open, handleClose } = props

    function handleCancel(e: MouseEvent) {
        handleClose("policyRefunds")
    }

    return (
        <Dialog
            open={open}
            onClose={handleCancel}
            fullWidth
            maxWidth="md"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            PaperProps={{
                style: {
                    borderRadius: "15px", // Adjust the value as needed
                }
            }}
        >
            <DialogTitle id="scroll-dialog-title">
                <div className="columns">
                    <div className="column is-6">
                        <h3 className="content-sub-header content-left">{t("SETTING_PAGE.POLICY_REFUNDS")}</h3>
                    </div>
                </div>
            </DialogTitle>
            <DialogContent dividers={false} style={{ height: "600px" }}>
                <div className="box" style={{ borderRadius: "30px" }}>
                    <div className="detail-dialog">
                        <div className="columns">
                            <div className="column">
                                <h3 className="content-sub-header font-bold">{t("SETTING_PAGE.POLICY_REFUNDS_DIAG.HEADER")}</h3>
                            </div>
                        </div>
                        <div className="columns">
                            <div className="column">
                                <p className="content-left" style={{ marginBottom: "15px", fontSize: "16px" }}>{t("SETTING_PAGE.POLICY_REFUNDS_DIAG.CONTENT")}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <div className="column">
                    <div className="buttons content-center">
                        <button className="button is-rounded is-link App" type="button" onClick={handleCancel}>{t("BUTTONS.BACKWARD")}</button>
                    </div>
                </div>
            </DialogActions>
        </Dialog>
    );
}
