import { useQuery } from '@tanstack/react-query';
import api from '../axios';

export const useOverview = () => {
  return useQuery({
    queryKey: ['overview'],
    queryFn: async () => {
      try {
        const { data } = await api.get(`/overview`);
        return data;
      } catch (error) {
        throw error;
      }
    },
  });
};
