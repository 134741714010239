import { PDFViewer, Font } from '@react-pdf/renderer';
import React, { useState } from 'react';
import Template from './template';
import EditToolsBar from './edit-tools-bar';

export type TemplateType = {
  num_credit: string;
  content: string;
};

export const fileTh = `${process.env.PUBLIC_URL}/assets/certificate/certificate-th.png`;
export const fileEn = `${process.env.PUBLIC_URL}/assets/certificate/certificate-en.png`;
const Certificate = () => {
  const [dataTemplate, setDataTemplate] = useState({
    num_credit: 'ZC-01-14',
    content: `การสัมมนา "ห้องสมุดสีเขียวกับการมุ่งสู่ Net Zero"
ในการประชุมสามัญประจำปี 2566 ชมรมห้องสมุดสีเขียว
และการประชุมผู้บริหารเครือข่ายห้องสมุดสีเขียว
1 พ.ย. 2566 โดย ชมรมห้องสมุดสีเขียว`,
  });

  const [fileTemplate, setFileTemplate] = useState(fileTh);

  Font.register({
    family: 'THSan',
    src: `${process.env.PUBLIC_URL}/assets/fonts/TH-Kanit/Kanit-Light.ttf`,
  });

  return (
    <div className="w-full relative max-w-screen-lg">
      <PDFViewer showToolbar={false} height="810" width={'100%'} className="max-w-screen-lg">
        <Template dataTemplate={dataTemplate} imageBackground={fileTemplate} />
      </PDFViewer>
      <EditToolsBar
        dataTemplate={dataTemplate}
        setDataTemplate={setDataTemplate}
        fileTemplate={fileTemplate}
        setFileTemplate={setFileTemplate}
      />
    </div>
  );
};

export default Certificate;
