import React, { useEffect, useState, MouseEvent, ChangeEvent } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { ToggleButton, ToggleButtonGroup, Box, Radio } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getSessionStorageData, setSessionStorageData } from '@src/constants/screen/util';
import './payment.css';

export default function Offset() {
    const { t } = useTranslation();
    const { id, event } = useParams()
    const [selectedValue, setSelectedValue] = useState<string | null>('');
    const [isDisableBtn, setIsDisableBtn] = useState<boolean>(true)

    useEffect(() => {
        let tmpCreateData = getSessionStorageData("tmpCreateData");
        if (tmpCreateData?.paidFor) {
            setSelectedValue(tmpCreateData.paidFor)
            setIsDisableBtn(false)
        }
    }, []);

    const handleChange = (e: React.MouseEvent<HTMLElement>, nextView: string) => {
        let tmpCreateData = getSessionStorageData("tmpCreateData");
        tmpCreateData.status = event;
        tmpCreateData.paging = 8;
        if (nextView !== null) {
            tmpCreateData.paidFor = nextView;
            setSelectedValue(nextView);
            setIsDisableBtn(false)
        } else {
            tmpCreateData.paidFor = "";
            setIsDisableBtn(true)
            setSelectedValue(null);
        }
        setSessionStorageData("tmpCreateData", tmpCreateData)
    };

    return (
        <div className="column is-6 is-offset-3">
            <div className="box" style={{ marginBlockStart: '80px', borderRadius: '90px', height: "815px" }}>
                <div className="columns is-moblie">
                    <div className="column" style={{ marginBlockStart: '20px' }}>
                        <h1 className="content-header content-left" style={{ paddingLeft: "46px" }}>{t('PAID_PAGE.HEADER')}</h1>
                        <br />
                        <ToggleButtonGroup
                            color="info"
                            value={selectedValue}
                            exclusive
                            orientation="vertical"
                            onChange={handleChange}
                        >
                            <ToggleButton
                                value={t('PAID_PAGE.PROJECT1.NAME')}
                                aria-label="list"
                                style={{
                                    borderColor: '#E2E2E2',
                                    width: '520px',
                                    borderRadius: '10px',
                                    display: 'block',
                                    textTransform: 'none',
                                }}
                            >
                                <p className="content-left" style={{ fontSize: '20px' }}>
                                    {t('PAID_PAGE.PROJECT1.NAME')}
                                </p>
                                <p className="content-right" style={{ fontSize: '20px' }}>
                                    {t('PAID_PAGE.PROJECT1.PRICE')}
                                </p>
                            </ToggleButton>
                            <br />
                            <ToggleButton
                                value={t('PAID_PAGE.PROJECT2.NAME')}
                                aria-label="list"
                                style={{
                                    borderColor: '#E2E2E2',
                                    width: '520px',
                                    borderRadius: '10px',
                                    display: 'block',
                                    textTransform: 'none',
                                }}
                            >
                                <p className="content-left" style={{ fontSize: '20px' }}>
                                    {t('PAID_PAGE.PROJECT2.NAME')}
                                </p>
                                <p className="content-right" style={{ fontSize: '20px' }}>
                                    {t('PAID_PAGE.PROJECT2.PRICE')}
                                </p>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                </div>
                <div className="columns">
                    <div className="column is-6 is-offset-3" style={{ alignItems: 'right', marginTop: "340px" }}>
                        <Link to="/register/post/8"><button className="button is-large is-fullwidth App" style={{ backgroundColor: "#C8F026", fontSize: "18px", height: "70px" }} disabled={isDisableBtn}>{t("BUTTONS.NEXT")}</button></Link>
                    </div>
                </div>
                <br />
            </div>
        </div>
    );
}
